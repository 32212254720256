import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {DefaultService} from '@core/services/default.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceBranchesService extends DefaultService {
  formName: '#coato-branches';
  per_page = 15;

  private coatoSelectedSubject = new Subject<any>();

  sendCoatoData(data: any) {
    this.coatoSelectedSubject.next(data);
  }

  getCoatoData() {
    return this.coatoSelectedSubject.asObservable();
  }

  getUrl(): string {
    return `crm/api/v1/coato-branches`;
  }
}
